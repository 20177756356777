import { captureRemixErrorBoundaryError } from "@sentry/remix";
import type { ProviderActionResponse } from "app/provider-actions.types.ts";
import { useEnv } from "app/root.tsx";
import { useTailwindScreenSizes } from "hooks/useTailwindScreenSizes.tsx";
import { useOptionalUser } from "hooks/useUser.tsx";
import { Mail, Phone } from "iconoir-react";
import {
  type ErrorResponse,
  isRouteErrorResponse,
  useRouteError,
} from "react-router";
import { cn, getErrorMessage, useIsDevmode } from "utils/utils.ts";
import errorImage from "../routes/_public+/_assets+/404.jpeg";
import { HomeArticle } from "./Homepage/HomeArticle.tsx";
import { HomeSection } from "./Homepage/HomeSection.tsx";
import { OptimizedImage } from "./OptimizedImage.tsx";
import { ContactUsSection } from "./Sections/ContactUsSection.tsx";
import { Headline } from "./UI/Headline.tsx";
import { Label } from "./UI/Label.tsx";
import { ClintButton } from "./shadcn-ui/button.tsx";
import vector from "/images/vector.png";

type StatusHandler = (info: {
  error: ErrorResponse & { data: ProviderActionResponse };
  params: Record<string, string | undefined>;
}) => React.ReactNode | null;

export function GeneralErrorBoundary({
  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  defaultStatusHandler = ({ error }): React.ReactNode => (
    <Label
      size="M"
      className="text-rouge bg-red-50 px-2 py-1 rounded-clint border-red-300 text-xl"
    >
      {error.status}{" "}
      {typeof error.data?.message === "string"
        ? error.data?.message
        : error.data?.message?.join(", ")}
    </Label>
  ),
  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  statusHandlers,
  // biome-ignore lint/correctness/noUnusedVariables: <explanation>
  unexpectedErrorHandler = (error): React.ReactNode => (
    <Label
      size="M"
      className="text-rouge bg-red-50 px-2 py-1 rounded-clint border-red-300 text-xl"
    >
      {getErrorMessage(error)}
    </Label>
  ),
}: {
  defaultStatusHandler?: StatusHandler;
  statusHandlers?: Record<number, StatusHandler>;
  unexpectedErrorHandler?: (error: unknown) => React.ReactNode | null;
}) {
  const error = useRouteError();
  captureRemixErrorBoundaryError(error);
  // const params = useParams();
  const isDev = useIsDevmode();
  const user = useOptionalUser();

  if (typeof document !== "undefined") {
    console.error(error);
  }

  const { isLargeScreen } = useTailwindScreenSizes({});
  const { phoneDisplay, phoneLink, CONTACT_EMAIL } = useEnv() || {};
  const errorMessage = getErrorMessage(error);

  return (
    <>
      <HomeSection
        color={isLargeScreen ? "gray100" : "white"}
        verticalPadding={"clint"}
        horizontalPadding={"clint"}
      >
        <HomeArticle color="transparent" horizontalPadding="none">
          <div className="relative flex flex-wrap items-center gap-8 lg:gap-12">
            <div className="inset-0 z-20 flex items-center relative lg:absolute">
              <div
                className={cn(
                  "rounded-clint flex flex-col gap-5 lg:border lg:border-gray-200 bg-white lg:p-[2.125rem] lg:basis-[672px]",
                  {},
                )}
              >
                <div className="flex flex-col gap-2">
                  <Label size="M" className="text-gray-600">
                    Erreur{" "}
                    {isRouteErrorResponse(error) ? error.status : "inconnue"}
                  </Label>
                  <Headline size="h1" as="div">
                    Oups BadCollect ...
                  </Headline>
                </div>
                <Label size="M" className="py-5 text-xl">
                  Nous n'arrivons pas à trouver la page que vous recherchez.
                  Essayez de revenir à la page d'accueil.
                </Label>

                {isDev || user?.authOptions.isAdmin ? (
                  <Label
                    size="M"
                    className="text-rouge bg-red-50 px-2 py-1 rounded-clint border-red-300 text-xl"
                  >
                    {errorMessage}
                  </Label>
                ) : null}
                {/* {errorMessage2} */}

                <ClintButton
                  as="Link"
                  linkProps={{
                    to: "/",
                    reloadDocument: true,
                  }}
                  variant="primary"
                  size="lg"
                  labelSize="L"
                  className="w-fit"
                >
                  Revenir à l'accueil
                </ClintButton>
                <div className="flex w-full flex-wrap items-center gap-8">
                  <ClintButton
                    as="Link"
                    iconPosition="left"
                    linkProps={{
                      to: phoneLink || "#",
                      reloadDocument: true,
                    }}
                    variant="secondaryGray100"
                    size="lg"
                    Icon={Phone}
                    className="grow basis-[240px]"
                  >
                    {phoneDisplay}
                  </ClintButton>
                  <ClintButton
                    as="Link"
                    iconPosition="left"
                    linkProps={{
                      to: `mailto:${CONTACT_EMAIL}` || "#",
                      reloadDocument: true,
                    }}
                    variant="secondaryGray100"
                    size="lg"
                    Icon={Mail}
                    className="grow basis-[240px]"
                  >
                    {CONTACT_EMAIL}
                  </ClintButton>
                </div>
              </div>
            </div>

            <div className="relative ml-auto w-fit lg:min-w-[500px]">
              <OptimizedImage
                maxWidth={1440 * 1.5}
                width={1440 * 1.5}
                alt="Erreur BadCollect"
                height={694 * 1.5}
                source={errorImage}
                className="h-auto w-full ml-auto "
                containerClassName="mx-0 relative rounded-clint overflow-hidden"
                imageProps={{
                  minHeight: "100%",
                  objectFit: "cover",
                }}
              >
                <div className="absolute inset-0 z-10 flex h-full w-full items-center justify-center">
                  <img src={vector} className="" width={530} height={530} alt="cercle GoodCollect" />
                </div>
              </OptimizedImage>
            </div>
          </div>
        </HomeArticle>
      </HomeSection>
      <ContactUsSection />
    </>
  );
}
