import type { useMarketplaceSideFilters } from "hooks/useMarketplaceSideFilters.tsx";
import { MarketplaceSideFilterWrapper } from "../MarketplaceSideFilters.tsx";
import { SideFilterCheckbox } from "./SideFilterCheckbox.tsx";
export const ProviderFilter = ({
  selectedProviders,
  toggleProvider,
}: {
  selectedProviders: ReturnType<
    typeof useMarketplaceSideFilters
  >["sideFilters"]["selectedProviders"];

  toggleProvider: ReturnType<
    typeof useMarketplaceSideFilters
  >["toggleProvider"];
}) => {
  return (
    <MarketplaceSideFilterWrapper title="★ Prestataires">
      <div className="flex flex-col gap-4">
        {selectedProviders
          // .sort((a, b) => b - a)
          .map((option) => {
            return (
              <SideFilterCheckbox
                checked={option.checked}
                toggleOption={() => {
                  toggleProvider(option.providerName);
                }}
                optionName={option.providerName}
                optionLabel={`(${option.count})`}
                optionGcId={option.providerName}
                key={`provider-${option.providerName}`}
                // isDisabled={!isAvailable}
              />
            );
          })}
      </div>
    </MarketplaceSideFilterWrapper>
  );
};
