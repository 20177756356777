export type ListFiltersParams = {
  page?: number;
  itemsPerPage?: number;
  search?: string;
  pageIndex?: number;
  skipIndex?: number;
  filter?: string[];
};

export type BookingStatusFilter =
  | "all"
  | "pending"
  | "in_progress"
  | "finalized"
  | "closed";

export type PaginationType = {
  currentPage: number;
  totalPages: number;
  totalItems: number;
  itemsPerPage: number;
  maxItemsPerPage: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
};

export const defaultPagination: PaginationType = {
  currentPage: 1,
  itemsPerPage: 0,
  maxItemsPerPage: 0,
  totalItems: 0,
  totalPages: 1,
  canNextPage: false,
  canPreviousPage: false,
};

export type MarketplaceOfferType = {
  priceHT: number;
  priceTTC: number;
  wastePricePerTonHT: number;
  businessName: string;
  rentPriceHT: number;
  rentPriceTTC: number;
  rentPricePerDayHT: number;
  rentPricePerDayTTC: number;
  rentFreeDays: number;
  wastePricePerTonTTC: number;
  treatmentPriceRuleId: number;
  equipmentPriceRuleId: number;
  providerId: string;
  equipment: {
    equipmentTypeName: string;
    name: string;
    imageUrl: string;
    priceRuleId: number;
    width: number;
    height: number;
    length: number;
    volume: number;
    type: {
      id: number;
      name: string;
    };
    equipmentWeight: number;
  };
  treatmentTypeId: number;
  waste: {
    gcId: number;
    id: number;
    name: string;
  };
  isPlan: boolean;
  distanceInKm: number;
};
export type MarketplaceOffersType = {
  bestPlanOffer: MarketplaceOfferType | null;
  bestTonOffer: MarketplaceOfferType | null;
  otherOffers: MarketplaceOfferType[];
  hiddenOffers: MarketplaceOfferType[]; // Hidden offers are paginated offers that have not been shown yet
  isStreaming: boolean;
  percentage: number;
  availableEquipmentTypes: { id: number; name: string }[];
  equipmentTypes: {
    id: number;
    name: string;
  }[];
  availableVolumes: number[];
  volumes: number[];

  pagination: PaginationType;
  // mostExpensivePrice: number;
  // mostVoluminousOffer: number;
  treatmentTypeData: {
    treatmentTypes: {
      name: string;
      gcId: number;
    }[];
    selectedTreatmentTypes: {
      name: string;
      gcId: number;
    }[];
    disabledTreatmentTypes: {
      name: string;
      gcId: number;
    }[];
  };
};
