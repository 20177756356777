import { useLocation } from "react-router";

export const useLogoutUrl = () => {
  const location = useLocation();
  // let redirectTo = '/';

  // if (!location) {
  // 	return { redirectTo: '/' };
  // }

  const redirectTo = encodeURIComponent(location.pathname + location.search);

  return { redirectTo };
};
