import { useEnv } from "app/root.tsx";
import { WebsocketIndicator } from "components/WebsocketIndicator.tsx";
import React, {
  createContext,
  useContext,
  useRef,
  type ReactNode,
} from "react";
import { useLocation } from "react-router";
import { io, type Socket } from "socket.io-client";
import { useIsDevmode } from "utils/utils.ts";

type ProviderProps = {
  // socket: Socket | undefined;
  // isSocketConnected: boolean;
  children: ReactNode;
};

const context = createContext<{
  socket: Socket | null;
  isSocketConnected: boolean;
}>({ socket: null, isSocketConnected: false });

export function useSocket() {
  if (!context) {
    throw new Error("Please use 'SocketProvider' to wrap your component.");
  }
  return useContext(context);
}

export function SocketProvider({ children }: ProviderProps) {
  const WEBSOCKET_URL = useEnv()?.WEBSOCKET_URL || "";
  const isDev = useIsDevmode();
  const location = useLocation();
  const [isSocketConnected, setIsSocketConnected] =
    React.useState<boolean>(false);

  const socketRef = useRef<Socket | null>(null);
  React.useEffect(() => {
    socketRef.current = io(WEBSOCKET_URL, {
      reconnection: true,
      reconnectionDelay: 150,
      reconnectionAttempts: Number.POSITIVE_INFINITY,
    });
    const socket = socketRef.current;
    if (!socket) return;

    socket.emit("connection");

    const handleConfirmation = () => {
      setIsSocketConnected(true);
    };

    const handleDisconnect = () => {
      setIsSocketConnected(false);
    };

    socket.on("confirmation", handleConfirmation);
    socket.on("disconnect", handleDisconnect);

    return () => {
      socket.off("confirmation", handleConfirmation);
      socket.off("disconnect", handleDisconnect);
      socket.disconnect();
    };
  }, [WEBSOCKET_URL, location.key]);

  React.useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
        socketRef.current = null;
      }
    };
  }, []);

  return (
    <context.Provider value={{ socket: socketRef.current, isSocketConnected }}>
      {isDev ? (
        <WebsocketIndicator isSocketConnected={isSocketConnected} />
      ) : null}
      {children}
    </context.Provider>
  );
}
