import { useEffect, useState } from "react";
import { Link, type LinkProps, NavLink, type NavLinkProps } from "react-router";

export const ObfuscatableLink = ({
  obfuscate,
  prefetch,
  ...props
}: LinkProps & { obfuscate: boolean }) => {
  const [isObfuscated, setIsObfuscated] = useState(obfuscate);
  useEffect(() => {
    if (!isObfuscated) return;
    setIsObfuscated(false);
  }, [isObfuscated]);
  if (isObfuscated) {
    return <span className={props.className}>{props.children}</span>;
  }
  return <Link {...props}>{props.children}</Link>;
};

export const ObfuscatableNavLink = ({
  obfuscate,
  prefetch,
  ...props
}: NavLinkProps & { obfuscate: boolean }) => {
  const [isObfuscated, setIsObfuscated] = useState(obfuscate);
  useEffect(() => {
    if (!isObfuscated) return;
    setIsObfuscated(false);
  }, [isObfuscated]);
  if (isObfuscated) {
    return (
      <span
        className={typeof props.className === "string" ? props.className : ""}
      >
        {props.children as React.ReactNode}
      </span>
    );
  }
  return <NavLink {...props}>{props.children}</NavLink>;
};
