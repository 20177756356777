import { OptimizedImage } from "components/OptimizedImage";
import type { getCustomerPaymentMethods } from "server/api/customer/services/customer-billing.server";
import { cn } from "utils/utils";
import mastercardIcon from "/images/logo-mastercard.png";
import visaIcon from "/images/logo-visa.png";

type PaymentMethodProps = Awaited<
  ReturnType<typeof getCustomerPaymentMethods>
>["creditCards"][number];

export const PaymentMethodCard = ({
  className,
  creditCard: { brand, isDefault, expMonth, expYear, last4 },
}: { creditCard: PaymentMethodProps; className?: string }) => {
  const iconSrc = brand === "visa" ? visaIcon : mastercardIcon;

  return (
    <div className={cn("flex flex-col gap-1", className)}>
      {isDefault ? (
        <span className="text-xs text-gray-600">Carte par défaut</span>
      ) : null}

      <div
        className={cn(
          "bg-white border border-slate-500 px-2 py-3 flex flex-row gap-2 rounded-sm",
          {
            "border-primary": isDefault,
          },
        )}
      >
        <OptimizedImage
          source={iconSrc}
          width={40}
          height={40}
          alt={`${brand} icon`}
          className="shrink-0"
          imageProps={{
            minHeight: "auto",
            objectFit: "contain",
          }}
          containerClassName="shrink-0"
        />

        <div className="flex flex-col">
          <p className="shrink-0 text-sm">••••{last4}</p>
          <p className="shrink-0 text-gray-600 text-xs">
            Expires {expMonth.toString().padStart(2, "0")}/
            {expYear.toString().slice(-2)}
          </p>
        </div>
      </div>
    </div>
  );
};
